$('#mobileNav ul').first().append('<li><a class="text" href="/members">Members Area</a></li>');

$('#mobileNav').mmenu(
{
        extensions: ["position-right"],
        "navbars": [
            {
                "position": "bottom",
                "content": $('.headerActions a.icn').clone()
            }
        ]
    });

$('iframe[src*=youtube],iframe[src*=vimeo]').wrap('<div class="embed-responsive embed-responsive-16by9"></div>');
$('iframe[src*=youtube],iframe[src*=vimeo]').addClass('embed-responsive-item');

$('[data-countdown]').each(function () {
    var dateStr = $(this).attr('data-countdown');
    var eventDate = new Date(dateStr);

    $(this).countdown("2022/01/01", function (event) {
        $(this).html(
            event.strftime('<span class="countdown-value countdown-value-d">%D</span>days <span class="countdown-value countdown-value-h">%H</span>h <span class="countdown-value countdown-value-m">%M</span>m <span class="countdown-value countdown-value-s">%S</span>s')
        );
    });

    //$(this).countdown({
    //    until: eventDate
    //});
});